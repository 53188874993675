
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-0.jpg?alt=media&token=a9a49391-c0f2-434f-982e-bbec4f58c1b6'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-1.jpg?alt=media&token=502f6a50-b1ee-4727-ab7f-c102357d47e3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-2.jpg?alt=media&token=a329d89c-b50e-46c7-a3b4-5c527db8bb4d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-3.jpg?alt=media&token=d1e83475-ac8c-4652-bfbd-e622c6cf54d1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-4.jpg?alt=media&token=43f0f46a-3d1d-4935-96de-d800f360328f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-5.jpg?alt=media&token=8f650d73-6b68-4ff9-85f9-0d85fb4caa56'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-6.jpg?alt=media&token=650a3f42-4896-43c9-b607-315d68f46caf'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-7.jpg?alt=media&token=2f31f698-b8fc-4ece-b17f-8c11bcc6b8b2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-8.jpg?alt=media&token=f19a817d-4a17-42a5-8e67-b6fb6c2adc7b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-9.jpg?alt=media&token=ff552812-f243-408e-aad9-56053ecf626a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-10.jpg?alt=media&token=11c64ba9-7bc4-4bca-b8cd-9a6fe95edd7a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-11.jpg?alt=media&token=da77eac5-49b5-4065-be81-193287a0201c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-12.jpg?alt=media&token=a6cfaab5-33a0-4f5e-8d40-d0370bc9f3d8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-13.jpg?alt=media&token=55cf1106-f214-4ad4-b775-cae5c64b50f6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-14.jpg?alt=media&token=39f9a734-5006-456d-8601-aaf59b5eec35'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-15.jpg?alt=media&token=7804d28c-be1c-4ec4-ad6e-5286ca84860c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-16.jpg?alt=media&token=4dd6552c-7ca9-4a9f-a189-b89cfcd54194'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-17.jpg?alt=media&token=11fae71f-91c7-4c7f-84de-efdfc5de1ee8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-18.jpg?alt=media&token=a26c10e5-2228-4f8d-9694-75ec56198407'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-19.jpg?alt=media&token=76906862-6907-4612-8504-db6074771229'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-20.jpg?alt=media&token=2b1e54aa-0029-460f-8a76-170fa9d03923'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-21.jpg?alt=media&token=434e2629-72b5-4257-b138-b2c06a527b16'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2F4b3ed8336aa5f5d531f9a06adce9ea15-22.jpg?alt=media&token=94a23eb0-8675-4238-9488-babfb9deaeb1'} alt="boohoo" className="img-responsive"/>
          </div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU3ES%2FDIVU3ESMAT.pdf?alt=media&token=64e1cf9c-8b3e-427d-9ba8-c28389cec321' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
